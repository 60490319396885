import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/BaseFare.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function EditProductFilterModal(props) {
  const closeAddModal = () => {
    props.handleClose();
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">
            ProductPricing Already Exist, are you sure you want to update?
          </h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <div className="btns-save-cancel">
          {!props.editMode && (
            <SBSaveUpdateBtn
              onClickEffect={props.enableInputFields} // Call the function to enable input fields
              btnName="Update"
            />
          )}
          {/* {props.editMode && (
            <SBSaveUpdateBtn
              onClickEffect={props.editProducts}
              btnName="Save"
            />
          )} */}
          <SbCancelBtn onClickEffect={closeAddModal} btnName="Cancel" />
        </div>
      </Modal>
    </div>
  );
}

export default EditProductFilterModal;
