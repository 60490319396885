import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import { FormattedAmount, dateFormatLocal } from "../FormattedAmount";
import { useDispatch, useSelector } from "react-redux";
import { loadPaymentData } from "../../redux/action";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Pay from "./Pay";
import { Col, Row } from "react-bootstrap";
import { optionsStatusList } from "../DropdownsData";
import Select from "react-select";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useReactToPrint } from "react-to-print";

function PaymentRequestedTable() {
  let tableHeader = [
    { name: "Passbook Id", align: "center", width: "120px" },
    { name: "User Name", align: "left", width: "150px" },
    { name: "Referral Code", align: "left", width: "150px" },
    { name: "BDE", align: "left", width: "150px" },
    { name: "Transaction Date", align: "left", width: "150px" },
    { name: "Claimed", align: "left", width: "150px" },
    { name: "To Be Paid", align: "left", width: "150px" },
  ];

  let dispatch = useDispatch();
  const { PaymentData } = useSelector((state) => state.data);
  // PAGINATION START
  const [PageSize, setPageSize] = useState(50);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  //code for edit

  const [updateShow, setUpdateShow] = useState(false);
  const [salesBeeReferelId, setSalesBeeReferelId] = useState();
  const editRefferal = (refId) => {
    setSalesBeeReferelId(refId);
    setUpdateShow(!updateShow);
  };
  const handleCloseUpdate = () => {
    setSalesBeeReferelId("");
    setUpdateShow(!updateShow);
  };

  //code for filter

  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterArray, setFilterArray] = useState([]);
  const [activeStatus, setActiveStatus] = useState("");

  //code for search bar

  const [searchValue, setSearchValue] = useState("");

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "ReferelCode");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "ReferelCode", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "ReferelCode");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      } else {
        list = [];
      }
      list.push({ key: "Status", value: data.value });
      setActiveStatus(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  const fethPaymentData = () => {
    dispatch(loadPaymentData(filterArray, PageSize, currentPage, setIsLoading));
  };

  useEffect(() => {
    fethPaymentData(filterArray, setIsLoading);
  }, [filterArray, setIsLoading]);

  const clearSerachBySelect = () => {
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray([]); // Clear the filter array
  };

  const [gfRefPassbookId, setGfRefPassbookId] = useState();

  const editPaymentDetails = (gfPassId) => {
    setGfRefPassbookId(gfPassId);
    console.log(gfPassId, "gfPassId");
    setShow(!show);
  };

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div style={{ position: "relative" }}>
          <Row className="search-row">
            <Col md={3} className="cat-col">
              <div className="page-header-text-div">
                {/* <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div> */}
                <h6 className="page-header-text">Payment (Requested)</h6>
              </div>
            </Col>
            <Col md={5} className="cat-col">
              <div className="input-div">
                <input
                  type="search"
                  placeholder="Search By Referral Code"
                  className="form-control setting-input"
                  id="metricInfo_search_bar"
                  value={searchValue}
                  onChange={(e) => setSearchData(e.target.value)}
                />
                <i className="fa fa-search search-icon"></i>
              </div>
            </Col>
            <Col md={4} className="cat-col">
              <div className="search-bar-header" style={{ float: "right" }}>
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <div
                    className="header-filter-btn"
                    onClick={() => setOpenFilterTab(!openFilterTab)}
                  >
                    <p className="card-head">
                      <i className="bi bi-sliders" />
                      &nbsp;&nbsp;Filters
                    </p>
                  </div>
                  {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                </div>
              </div>
            </Col>
          </Row>

          {openFilterTab && (
            <div className="page-filter-section">
              <div style={{ width: "100%" }}>
                <Row>
                  <Col md={7}>
                    <div className="clients-filter-input-boxs filter-box-div">
                      <div style={{ width: "100%", padding: "0px 5px" }}>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          // defaultValue={optionsRoleList[0].label}
                          options={optionsStatusList}
                          placeholder="Select Status"
                          value={activeStatus && activeStatus}
                          onInputChange={optionsStatusList.label}
                          onChange={(data) =>
                            setSelectSearchDrop(data, "status")
                          }
                          isSearchable={true}
                          // isClearable={true}
                        />
                      </div>
                      <div
                        style={{ width: "100%", padding: "0px 5px" }}
                        className="input-group from-to-date-select"
                      >
                        <span className="input-group-text" id="from-label">
                          From - To
                        </span>
                        <span className="date-picker-with-icon">
                          <DatePicker
                            selected={fromDate}
                            className="select from-date"
                            id="date-range-picker"
                            dateFormat="dd/MM/yyyy"
                            selectsRange
                            startDate={fromDate}
                            endDate={toDate}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "date")
                            }
                            maxDate={new Date()}
                          />
                        </span>
                        <span>
                          <i
                            className="fa fa-calendar-o calander-icon"
                            style={{ right: "15px" }}
                          />
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="clients-filter-input-boxs filter-box-div">
                      <SbCancelBtn
                        onClickEffect={clearSerachBySelect}
                        btnName="Clear"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>

        <div className="sb-table-div sb-table-setion">
          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            className="table-main-div"
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                aria-label="sticky table table-responsive"
                className="basefare-table"
                ref={componentRef}
              >
                <TableHead className="custom-table-header">
                  <TableRow>
                    {tableHeader.map((name, i) => (
                      <TableCell
                        key={i}
                        align={name.align}
                        className={`thead-cell-products`}
                        style={{ minWidth: name.width }}
                      >
                        {name.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <>
                    {tableHeader.map((name, i) => (
                      <SkeletonMeetPurpose key={i} />
                    ))}
                  </>
                ) : (
                  <>
                    {PaymentData && PaymentData.length === 0 ? (
                      <TableBody>
                        <TableRow align="center">
                          <TableCell
                            align="center"
                            colSpan={tableHeader.length}
                          >
                            <div style={{ padding: "20px 20px" }}>
                              <img
                                src="../../../../images/DefaultImages/NodataFound.jpg"
                                alt=""
                                style={{ width: "10%" }}
                              />
                              <br />
                              <span>No Data Found...!</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {PaymentData &&
                          PaymentData.map((data, i) => (
                            <TableRow
                              align="center"
                              key={i}
                              className="client-list-row-data"
                            >
                              <TableCell align="center">
                                {data.gfRefPassbookID}
                              </TableCell>
                              <TableCell>
                                <p>{data.userName}</p>
                              </TableCell>
                              <TableCell>
                                <strong>{data.referelCode}</strong>
                                <br />
                              </TableCell>
                              <TableCell>
                                <p>{data.bde}</p>
                              </TableCell>
                              <TableCell>
                                <p>{dateFormatLocal(data.transactionDate)}</p>
                              </TableCell>
                              <TableCell>
                                <div className="price-text-div">
                                  <div className="price-div">
                                    <span className="price-text">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {FormattedAmount(data.claimed)}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell align="left">
                                <button
                                  className="status-badge complete-status"
                                  style={{ display: "flex", gap: "5px" }}
                                  onClick={() =>
                                    editPaymentDetails(data?.gfRefPassbookID)
                                  }
                                >
                                  Pay
                                  <div className="price-text-div">
                                    <div className="price-div">
                                      <span className="price-text">
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {FormattedAmount(data.claimed)}
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
        {show && (
          <Pay
            show={show}
            handleClose={handleClose}
            fethPaymentData={fethPaymentData}
            gfRefPassbookId={gfRefPassbookId}
            PaymentData={PaymentData}
          />
        )}
      </div>
      <div className="download-and-pagination">
        <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
      </div>
    </div>
  );
}

export default PaymentRequestedTable;
