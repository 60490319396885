import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadMachineData } from "../../../redux/action";
import Footer from "../../Footer";
import { MiCustomBtn } from "../../SbButtons/SbAddBtn";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/CustomForms.scss";
import DisplayFormAttachments from "./DisplayFormAttachments";
import OnScrollLoading from "../../OnScrollLoading";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { SbDropdownListBtn } from "../../SbButtons/SbDropDownBtn";
import BulkMachineUpload from "./BulkMachineUpload";
import ReAssignCustomerBulk from "./ReAssignCustomerBulk";
import ReAssignModal from "./ReAssignModal";

const DisplayTableData = () => {
  let dispatch = useDispatch();
  const { dForm_ModuleName, dForm_ID } = useParams();
  const { getMachineData } = useSelector((state) => state.data);

  const [isLoading, setIsLoading] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formFields, setFormfields] = useState([]);
  const [reAssignEnable, setReAssignEnable] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  //   const [iFormNames, setIFormNames] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);

  const [addBulkMachineModelshow, setAddBulkMachineModelshow] = useState(false);
  const [reAssignCustomerModelshow, setReAssignCustomerModelshow] =
    useState(false);

  const toggleMenu = () => {
    setMenuCollapse(!menuCollapse);
    setToggleshift({ style: menuCollapse ? "" : "toggle-shift-left" });
  };

  const searchByLabel = (value) => {
    const filtered = getMachineData.flatMap((items) =>
      items
        .flatMap((data) =>
          data.data.filter(
            (field) =>
              field.iForm_FieldValue && // Ensure iForm_FieldValue exists
              field.iForm_FieldValue.toLowerCase().includes(value.toLowerCase()) // Case insensitive search
          ).length > 0
            ? data
            : null
        )
        .filter((item) => item !== null)
    );
    setFilterArray(filtered);
  };

  const setSearchData = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    searchByLabel(value);
  };

  const [filterMachineArray, setFilterMachineArray] = useState([]);
  const [PageSize, setPageSize] = useState(10000);
  const currentPage = 1;

  useEffect(() => {
    fectMachineData();
  }, [PageSize, currentPage]);

  const fectMachineData = () => {
    dispatch(loadMachineData(setIsLoading, PageSize, currentPage));
  };

  useEffect(() => {
    // Initialize filterArray with getMachineData
    if (getMachineData && getMachineData.length > 0) {
      setFilterArray(getMachineData.flat());
    }
    // console.log("M-DATA:", getMachineData.flat().length);
  }, [getMachineData]);

  const setSearchFilterData = (value) => {
    let list;
    if (filterMachineArray?.length !== 0) {
      list = filterMachineArray.filter(
        (item) => item.key !== "IForm_FieldValue"
      );
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "IForm_FieldValue", value: value });
    } else {
      list = filterMachineArray.filter(
        (item) => item.key !== "IForm_FieldValue"
      );
    }
    setSearchValue(value);
    setFilterMachineArray(list);
  };

  let navigate = useNavigate();

  const handleOpenAddForm = () => {
    navigate(`/createdform/${dForm_ID}`);
  };

  const handleEditForm = (id) => {
    navigate(`/createdform/${dForm_ID}`, { state: { rowId: id } });
  };

  const goToBackPage = () => {
    navigate(-1);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowFields, setSelectedRowFields] = useState([]);

  const handleViewDocuments = (id) => {
    const filteredFields = formFields.filter((field) => field.row_ID === id);
    setSelectedRowFields(filteredFields);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openBulkMachineUpload = () => {
    setAddBulkMachineModelshow(!addBulkMachineModelshow);
  };

  const openReAssignCustomerUpload = () => {
    setReAssignCustomerModelshow(!reAssignCustomerModelshow);
  };

  const handleClose = () => {
    setAddBulkMachineModelshow(false);
    setReAssignCustomerModelshow(false);
  };

  const [lastFieldNames, setLastFieldNames] = useState([]);

  useEffect(() => {
    if (
      getMachineData &&
      Array.isArray(getMachineData) &&
      getMachineData.length > 0
    ) {
      const lastArray = getMachineData[getMachineData.length - 1]; // Get the last array in getMachineData
      if (Array.isArray(lastArray) && lastArray.length > 0) {
        const lastItem = lastArray[lastArray.length - 1]; // Get the last object in the last array
        if (
          lastItem &&
          Array.isArray(lastItem.data) &&
          lastItem.data.length > 0
        ) {
          const fieldNames = lastItem.data
            .map((item) => item.iForm_FieldName)
            .filter(
              (fieldName) => fieldName && fieldName.toLowerCase() !== "submit"
            ); // Filter out 'submit'

          setLastFieldNames(fieldNames); // Set the filtered iForm_FieldName values to state
        } else {
          console.error("Last item does not contain a valid 'data' array");
        }
      } else {
        console.error("Last array is empty or not an array");
      }
    } else {
      console.error("getMachineData is empty or not an array");
    }
  }, [getMachineData]);

  const openAssignToUserModal = () => {
    setOpenReassignModal(!openReassignModal);
  };

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter((id) => id !== rowId);
      } else {
        return [...prevSelected, rowId];
      }
    });
  };

  const toggleSelectAll = (checked) => {
    if (checked) {
      const allRowIds = filterArray.map((item) => item.iForm_FormName);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
    setSelectAllCheck(checked);
  };

  return (
    <div id="main-page">
      {addBulkMachineModelshow && (
        <BulkMachineUpload
          show={addBulkMachineModelshow}
          handleClose={handleClose}
        />
      )}
      {reAssignCustomerModelshow && (
        <ReAssignCustomerBulk
          show={reAssignCustomerModelshow}
          handleClose={handleClose}
        />
      )}
      {!addBulkMachineModelshow && !reAssignCustomerModelshow && (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div className="clients-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          {dForm_ModuleName} ({filterArray.length})
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Search..."
                          className="form-control setting-input"
                          value={searchValue}
                          onChange={(e) => setSearchData(e)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header dflex-j-end"
                        style={{ float: "right" }}
                      >
                        <p
                          className="card-head color-green mrx-pdg-5"
                          onClick={() => setReAssignEnable(!reAssignEnable)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-retweet" />
                          &nbsp;&nbsp;Re-Assign
                        </p>
                        {reAssignEnable && (
                          <div className="header-filter-btn mrx-pdg-5">
                            <MiCustomBtn
                              btnName="Assign To"
                              onClickEffect={openAssignToUserModal}
                              iconName={"fa fa-user"}
                              btnColor="primary"
                              fontSize={"fz13"}
                              disableProp={
                                selectedRows.length > 0 ? false : true
                              }
                            />
                          </div>
                        )}
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <SbDropdownListBtn
                            openAddModal={handleClose}
                            pageName="Machines"
                            addSingle={handleOpenAddForm}
                            addBulk={openBulkMachineUpload}
                            reAssign={openReAssignCustomerUpload}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="sb-table-div sb-table-setion">
                    <Paper
                      sx={{ width: "100%", overflow: "hidden" }}
                      className="table-main-div"
                    >
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table table-responsive"
                          className="basefare-table custom-form-table"
                        >
                          <TableHead className="custom-table-header">
                            <TableRow>
                              <TableCell padding="checkbox">
                                {reAssignEnable && (
                                  <input
                                    type="checkbox"
                                    checked={selectAllCheck}
                                    onChange={(e) =>
                                      toggleSelectAll(e.target.checked)
                                    }
                                  />
                                )}
                              </TableCell>
                              {lastFieldNames.map((fieldName, index) => (
                                <TableCell key={index} align="left">
                                  {fieldName || "-"}
                                </TableCell>
                              ))}
                              <TableCell
                                style={{
                                  position: "sticky",
                                  right: 0,
                                  background: "white",
                                  zIndex: 20,
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {isLoading ? (
                            <>
                              <OnScrollLoading />
                            </>
                          ) : (
                            <>
                              <TableBody>
                                {filterArray.length === 0 ? (
                                  <TableRow align="center">
                                    <TableCell
                                      colSpan={lastFieldNames.length + 1}
                                    >
                                      <div style={{ padding: "20px 20px" }}>
                                        <img
                                          src="../../../../images/DefaultImages/NodataFound.jpg"
                                          alt=""
                                          style={{ width: "10%" }}
                                        />
                                        <br />
                                        <span>---No Data Found---</span>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  filterArray.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell padding="checkbox">
                                        {reAssignEnable && (
                                          <input
                                            type="checkbox"
                                            checked={selectedRows.includes(
                                              item.iForm_FormName
                                            )}
                                            onChange={() =>
                                              toggleRowSelection(
                                                item.iForm_FormName
                                              )
                                            }
                                          />
                                        )}
                                      </TableCell>
                                      {lastFieldNames.map(
                                        (fieldName, innerIndex) => (
                                          <TableCell key={innerIndex}>
                                            {fieldName === "Upload Document" ? (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#5842bc",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-eye"
                                                  onClick={() =>
                                                    handleViewDocuments(
                                                      item?.iForm_FormName
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  View Attachments
                                                </i>
                                              </div>
                                            ) : (
                                              item.data.find(
                                                (field) =>
                                                  field.iForm_FieldName ===
                                                  fieldName
                                              )?.iForm_FieldValue || "NA"
                                            )}
                                          </TableCell>
                                        )
                                      )}
                                      <TableCell
                                        style={{
                                          position: "sticky",
                                          right: 0,
                                          background: "white",
                                          zIndex: 10,
                                        }}
                                      >
                                        <button
                                          className="btn client-edit-link"
                                          onClick={() =>
                                            handleEditForm(item?.iForm_FormName)
                                          }
                                        >
                                          <i className="fa fa-pencil-square-o" />
                                          &nbsp; Edit
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </>
                          )}
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          <>
            {isModalOpen && (
              <DisplayFormAttachments
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                formFields={formFields}
                selectedRowFields={selectedRowFields}
              />
            )}
            {openReassignModal && (
              <ReAssignModal
                openReassignModal={openReassignModal}
                openAssignToUserModal={openAssignToUserModal}
                iFormNames={selectedRows}
                fectMachineData={fectMachineData}
              />
            )}
          </>
        </>
      )}
    </div>
  );
};
export default DisplayTableData;
