import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Leaves.scss";
import "../../../styles/CommonStyle.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { travelizeAuth } from "../../HeaderAuthentication";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  loadManagerDropdown,
  loadUserDropdown,
  loadUserDropdownByManager,
} from "../../../redux/action";

export default function ReAssignModal(props) {
  let dispatch = useDispatch();
  const { userDropdownByManager } = useSelector((state) => state.data);
  const { managerDropdown } = useSelector((state) => state.data);

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [fieldReq, setFieldReq] = useState(false);

  useEffect(() => {
    dispatch(loadManagerDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (selectedManager) {
      dispatch(loadUserDropdownByManager(selectedManager.value));
    } else {
      // Reset user dropdown if no manager is selected
      setSelectedUser(null);
    }
  }, [selectedManager, dispatch]);

  const updateReAssignUser = (e) => {
    e.preventDefault();
    if (selectedUser) {
      const reassignData = {
        User: {
          Code: selectedUser.code,
          IForm_FormName: props.iFormNames,
        },
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify({ reassignData }),
      };
      fetch(`${baseURL}/api/Essae/ReAssignEssae`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          console.log("UPDATE-MANAGER-RES::", res);
          if (res.success) {
            toast.success(`${res.message}`);
            props.openAssignToUserModal();
            props.fectMachineData();
          } else {
            toast.error(`${res.errorMessage}`);
          }
        });
    } else {
      toast.error(`Please Select User`);
    }
  };

  return (
    <div>
      <Modal show={props.openReassignModal} className="main_model">
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">ASSIGN TO</h6>
          <i className="fa fa-times" onClick={props.openAssignToUserModal} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row model-add-user-body">
              <div className="mb-1">
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={managerDropdown}
                  placeholder="Select Manager"
                  value={selectedManager}
                  onChange={setSelectedManager}
                  isSearchable={true}
                />
              </div>
              <div className="mt-2">
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={userDropdownByManager}
                  placeholder="Select User"
                  value={selectedUser}
                  onChange={(data) => setSelectedUser(data)}
                  isSearchable={true}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={updateReAssignUser} btnName="Save" />
          <SbCancelBtn
            btnName="Cancel"
            onClickEffect={props.openAssignToUserModal}
          />
        </div>
      </Modal>
      <></>
    </div>
  );
}
