import { Link, useLocation } from "react-router-dom";
import {
  loadServiceRepairReport,
  loadServiceRepairReportEmail,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";

const ServiceAndRepairReport = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { getServiceRepairReport } = useSelector((state) => state.data);

  const [complaintId, setComplaintId] = useState(location.state.id ?? "");

  //   useEffect(() => {
  // console.log("TTT", location.state);
  // if (location.state.id) {
  //   setComplaintId(location.state.id);
  // dispatch(loadServiceRepairReport(id))
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return { date: "N/A", time: "N/A" };
    }
    // Try multiple formats to parse the date string
    const dateObject = moment(dateTimeString, [
      "MM/DD/YYYY hh:mm:ss A",
      "MMM DD YYYY hh:mma",
      "M/D/YYYY h:mm:ss A",
      "M/D/YYYY h:mm:ss A",
    ]);
    if (!dateObject.isValid()) {
      return { date: "Invalid Date", time: "Invalid Time" };
    }
    return {
      date: dateObject.format("DD/MM/YYYY"),
      time: dateObject.format("hh:mm:ss A"),
    };
  };

  const complainedOn = formatDateTime(getServiceRepairReport?.complained_On);
  const attendedOn = formatDateTime(getServiceRepairReport?.attended_On);
  const rectifiedOn = formatDateTime(
    getServiceRepairReport?.duration_rectification
  );

  const handleReportMail = () => {
    dispatch(loadServiceRepairReportEmail(complaintId));
  };

  const handlePrint = () => {
    window.print();
  };

  const decodeBase64Image = (base64Image) => {
    return `data:image/jpeg;base64,${base64Image}`;
  };

  return (
    <div className="serviceandrepir-container">
      <div className="serviceandrepir-container-header">
        <div>
          <img src="../images/essaeLogo1.png" alt="" />
        </div>
        <div>
          <h5>Essae Digitronics Private Limited</h5>
          <p>Reg. Off : Plot - 34 & 35, Bommasandra Industrial Area,</p>
          <p>Anekal Taluk, Bangalore - 560099.</p>
          <p>Email : essae@essae.in</p>
        </div>
        <div>
          <img src="../images/essaeLogo2.png" alt="" />
        </div>
      </div>
      <div className="sb-table-div sb-table-setion service-report-table-div">
        <table
          style={{ width: "100%" }}
          className="basefare-table service-report-table"
        >
          <thead className="custom-table-header">
            <tr>
              <th colSpan="4">
                <p>No :{getServiceRepairReport?.sl_No}</p>
              </th>
              <th colSpan="4">
                <h5>SERVICE AND REPAIR REPORT</h5>
              </th>
              <th colSpan="4" style={{ textAlign: "right" }}>
                <p>Date : {getServiceRepairReport?.date}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4" rowSpan="5" style={{ verticalAlign: "top" }}>
                <p>CUSTOMER : {getServiceRepairReport?.customer}</p>
              </td>
              <td colSpan="6" style={{ textAlign: "center" }}>
                <p>Service Request</p>
              </td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td colSpan="2">
                <p>Date</p>
              </td>
              <td colSpan="2">
                <p>Time</p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p>Received on</p>
              </td>
              <td colSpan="2">
                <p>{complainedOn?.date}</p>
              </td>
              <td colSpan="2">
                <p>{complainedOn?.time}</p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p>Attended on</p>
              </td>
              <td colSpan="2">
                <p>{attendedOn?.date}</p>
              </td>
              <td colSpan="2">
                <p>{attendedOn?.time}</p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p>Duration from request to rectification</p>
              </td>
              <td colSpan="2">
                <p>{getServiceRepairReport.duration_rectification}</p>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <p>MODEL :{getServiceRepairReport?.model}</p>
              </td>
              <td colSpan="4">
                <p>CAPACITY :{getServiceRepairReport?.capacity}</p>
              </td>
              <td colSpan="4">
                <p>
                  MACHINE NUMBER : #{getServiceRepairReport?.machine_Number}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <p>
                  AMC/GUARANTEE/CHARGED :{getServiceRepairReport?.amc ?? "NA"}
                </p>
              </td>
              <td colSpan="3">
                <p>TYPE : {getServiceRepairReport?.type ?? "NA"}</p>
              </td>
              <td colSpan="3"></td>
            </tr>
            <tr>
              <td colSpan="10">
                <p>
                  DESCRIPTION OF COMPLAINT BY CUSTOMER :{" "}
                  {getServiceRepairReport?.complaint_By_Customer ?? "NA"}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="7">
                <p>
                  OBSERVATION BY SERVICE PERSONNEL :
                  {getServiceRepairReport?.service_Personnel ?? "NA"}{" "}
                </p>
              </td>
              <td colSpan="3">
                <p>
                  COMPLAINT CODE : #{getServiceRepairReport?.complaint_Code}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="10">
                <p>ACTION TAKEN : {getServiceRepairReport?.action_Taken}</p>
              </td>
            </tr>
            {/* <tr>
                            <td colSpan="7"><p>SPARES REPLACED: {getServiceRepairReport?.spares_Replaced ?? "NA"}</p></td>
                            <td colSpan="3"><p>SL.NO.OF SPARES: {getServiceRepairReport?.sl_No_of_Spares ?? "NA"}</p></td>
                        </tr> */}
            <tr>
              <td colSpan="10">
                <p>
                  SUGGESTIONS MADE TO CUSTOMER :
                  {getServiceRepairReport?.suggestions_To_Customer ?? "NA"}{" "}
                </p>
              </td>
            </tr>
            {/* <tr>
                            <td colSpan="5"></td>
                            <td colSpan="5"><h6>TO BE FILLED BY CUSTOMER ONLY</h6></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td colSpan="5"><p>Complained on : {getServiceRepairReport?.complained_On ?? "NA"}</p></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td colSpan="5"><p>Attended on : {getServiceRepairReport?.attended_On ?? "NA"}</p></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td colSpan="5"><p>Rectified on : {getServiceRepairReport?.rectified_On ?? "NA"}</p></td>
                        </tr> */}
            <tr>
              <td colSpan="6">
                <img
                  src={decodeBase64Image(
                    getServiceRepairReport?.clientComplaints_SolutionImage
                  )}
                  alt="solution image"
                  style={{ width: "300px", height: "300px" }}
                />
              </td>
              <td colSpan="6">
                <img
                  src={decodeBase64Image(
                    getServiceRepairReport?.clientComplaints_IssueImage
                  )}
                  alt="issue image"
                  style={{ width: "300px", height: "300px" }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p>
                  Note : This is a system generated report, no signature
                  required.{" "}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="serviceandrepir-container-header">
                <div>
                    <b>Signature and Stamp of Customer</b>
                </div>
                <div className="service-report-name-signature">
                    <b>Name of Service Personnel</b>
                    <b>Signature</b>
                </div>
            </div> */}
      <div className="Service-report-dowmload-btn no-print">
        <div>
          <i className="fa fa-whatsapp" style={{ color: "#63E6BE" }}></i>
          <Link>Send Whats app</Link>
        </div>
        <div
          onClick={() =>
            handleReportMail(getServiceRepairReport?.complaint_Code)
          }
        >
          <i className="fa fa-envelope" style={{ color: "#B197FC" }}></i>
          <Link>Send Email</Link>
        </div>
        <div onClick={handlePrint}>
          <i className="fa fa-download" style={{ color: "#B197FC" }}></i>
          <Link>Print Report</Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceAndRepairReport;
