import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyReportingData } from "../../redux/action";

export default function ReportingTo(props) {
  let clientId = props.clientId;
  let dispatch = useDispatch();
  const { companyReportingData } = useSelector((state) => state.data);

  useEffect(() => {
    if (clientId) {
      dispatch(loadCompanyReportingData(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <div>
      <Modal
        show={props.showReportingTo}
        onHide={props.handleClose}
        className="main_model mappedto-users-model"
      >
        <div className="add-edit-client-heading">
          <h6 className="add-edit-client-head">REPORTING TO</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="details-body">
          {props.isLoading ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <div className="table-responsive meetings-scrollbar">
              <table className="table meetings-list-table">
                {/* <thead className="thead-light meetings-list-thead">
                  <tr>
                    <th>Reporting To</th>
                  </tr>
                </thead> */}
                <tbody>
                  {companyReportingData &&
                    companyReportingData.map((data, i) => (
                      <tr key={i}>
                        <td>{data.clientName ?? "NA"}</td>
                      </tr>
                    ))}
                  {(!companyReportingData ||
                    companyReportingData.length === 0) && (
                    <tr>
                      <td>-----NO DATA FOUND----</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
