import moment from "moment";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

export const MeetingsChart = (props) => {
  let themeColor = localStorage.getItem("theme");

  const [colorArray, serColorArray] = useState([]);
  useEffect(() => {
    if (themeColor == "dark") {
      serColorArray(["#04A96D", "#24e5a3", "#fcb939", "#7ce5aa"]);
    } else {
      serColorArray(["#5842bc", "#7e69db", "#ff847f", "#b2a5ee"]);
    }
  }, [themeColor]);

  let graphData = props.graphData;
  let graphName = props.graphName;

  const [screenDimensions, setScreenDimensions] = useState(window.screen.width);
  const getScreenDimensions = (e) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setScreenDimensions(width);
  };

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);

    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  const [totalMeeting, setTotalMeeting] = useState([]);
  const [scheduleMeeting, setScheduleMeeting] = useState([]);
  const [directMeeting, setDirectMeeting] = useState([]);
  const [myMeeting, setMyMeeting] = useState([]);
  const [firstLable, setFirstLable] = useState("");
  const [secondLable, setSecondLable] = useState("");
  const [thirdLable, setThirdLable] = useState("");
  const [user, setUser] = useState([]);
  const [screenHeight, setSreenHeight] = useState(0);
  const [legendFont, setLegendFont] = useState("");
  const [legendMark, setLegendMark] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (graphData && graphData.length !== 0) {
      let totalArray = [];
      let scheduledArray = [];
      let directArray = [];
      let myVisitArray = [];
      let userArray = [];
      graphData.map((item) => {
        if (graphName === "meeting") {
          setFirstLable("Direct Visit");
          setSecondLable("Schedule Visit");
          setThirdLable("My Visit");
          totalArray.push(item.Total);
          scheduledArray.push(item.Scheduled);
          directArray.push(item.Direct);
          myVisitArray.push(item.MyVisit);
          // const dateParts = item.Date.split('/').slice(0,-2);
          let dateParts;
          if (item.Date) {
            dateParts = moment(item.Date, "DD/MM/YYYY").format("MMM DD");
          } else if (item.Month) {
            let currentYear = moment(new Date()).format("YYYY");
            let oneDate = "01/" + item.Month.toString() + "/" + currentYear;
            dateParts = moment(oneDate, "DD/MM/YYYY").format("MMM");
          }
          userArray.push(dateParts);
        } else if (graphName === "complaint") {
          setFirstLable("Pending");
          setSecondLable("FollowUp");
          setThirdLable("Resolved");
          totalArray.push(item.TotalCount);
          scheduledArray.push(item.Pending);
          directArray.push(item.FollowUp);
          myVisitArray.push(item.Resolved);
          // const dateParts = item.Date.split('/').slice(0,-2);
          let dateParts;
          if (item.Date) {
            dateParts = moment(item.Date, "DD/MM/YYYY").format("MMM DD");
          } else if (item.Month) {
            let currentYear = moment(new Date()).format("YYYY");
            let oneDate = "01/" + item.Month.toString() + "/" + currentYear;
            dateParts = moment(oneDate, "DD/MM/YYYY").format("MMM");
          }

          userArray.push(dateParts);
        } else if (graphName === "localclaim") {
          setFirstLable("Applied");
          setSecondLable("Approved");
          setThirdLable("Rejected");
          totalArray.push(item.Applied);
          directArray.push(item.Applied);
          scheduledArray.push(item.Approved);
          myVisitArray.push(item.Rejected);
          // const dateParts = item.Date.split('/').slice(0,-2);
          let dateParts;
          if (item.Date) {
            dateParts = moment(item.Date, "DD/MM/YYYY").format("MMM DD");
          } else if (item.Month) {
            let currentYear = moment(new Date()).format("YYYY");
            let oneDate = "01/" + item.Month.toString() + "/" + currentYear;
            dateParts = moment(oneDate, "DD/MM/YYYY").format("MMM");
          }
          userArray.push(dateParts);
        } else if (graphName === "osclaim") {
          setFirstLable("Applied");
          setSecondLable("Approved");
          setThirdLable("Rejected");
          totalArray.push(item.Applied);
          directArray.push(item.Applied);
          scheduledArray.push(item.Approved);
          myVisitArray.push(item.Rejected);
          // const dateParts = item.Date.split('/').slice(0,-2);
          let dateParts;
          if (item.Date) {
            dateParts = moment(item.Date, "DD/MM/YYYY").format("MMM DD");
          } else if (item.Month) {
            let currentYear = moment(new Date()).format("YYYY");
            let oneDate = "01/" + item.Month.toString() + "/" + currentYear;
            dateParts = moment(oneDate, "DD/MM/YYYY").format("MMM");
          }
          userArray.push(dateParts);
        } else if (graphName === "order") {
          setFirstLable("Order Mount");
          setSecondLable("");
          setThirdLable("");
          totalArray.push(item.OrderAmount);
          // scheduledArray.push(item.OrderAmount);
          directArray.push(item.OrderAmount);
          // myVisitArray.push(item.Rejected);
          // const dateParts = item.Date.split('/').slice(0,-2);
          let dateParts;
          if (item.Date) {
            dateParts = moment(item.Date, "DD/MM/YYYY").format("MMM DD");
          } else if (item.Month) {
            let currentYear = moment(new Date()).format("YYYY");
            let oneDate = "01/" + item.Month.toString() + "/" + currentYear;
            dateParts = moment(oneDate, "DD/MM/YYYY").format("MMM");
          }
          userArray.push(dateParts);
        }
      });
      setTotalMeeting(totalArray);
      setScheduleMeeting(scheduledArray);
      setDirectMeeting(directArray);
      setMyMeeting(myVisitArray);
      setUser(userArray);
    }
    if (screenDimensions) {
      if (screenDimensions >= 1920) {
        setSreenHeight(480);
        setLegendFont("13px");
        setLegendMark({ width: 12, height: 12 });
      } else if (screenDimensions >= 1440 && screenDimensions <= 1920) {
        setSreenHeight(430);
        setLegendFont("13px");
        setLegendMark({ width: 11, height: 11 });
      } else if (screenDimensions >= 1200 && screenDimensions <= 1439) {
        setSreenHeight(380);
        setLegendFont("12px");
        setLegendMark({ width: 10, height: 10 });
      } else if (screenDimensions >= 1024 && screenDimensions <= 1999) {
        setSreenHeight(250);
        setLegendFont("10px");
        setLegendMark({ width: 8, height: 8 });
      } else if (screenDimensions >= 992 && screenDimensions <= 1023) {
        setSreenHeight(230);
        setLegendFont("13px");
        setLegendMark({ width: 10, height: 10 });
      } else if (screenDimensions <= 992) {
        setSreenHeight(350);
        setLegendFont("12px");
        setLegendMark({ width: 9, height: 9 });
      }
    }
  }, [graphData, graphName, screenDimensions]);

  let seriesArray = [];
  if (graphName === "order") {
    seriesArray = [
      {
        name: firstLable,
        type: "column",
        data: directMeeting,
      },
      {
        name: "Total",
        type: "line",
        data: totalMeeting,
      },
    ];
  } else {
    seriesArray = [
      {
        name: firstLable,
        type: "column",
        data: directMeeting,
      },
      {
        name: secondLable,
        type: "column",
        data: scheduleMeeting,
      },
      {
        name: thirdLable,
        type: "column",
        data: myMeeting,
      },
      {
        name: "Total",
        type: "line",
        data: totalMeeting,
      },
    ];
  }

  const [columnWidth, setColumnWidth] = useState("");
  useEffect(() => {
    if (graphData.length == 1) {
      setColumnWidth("15%");
    } else if (graphData.length === 3) {
      setColumnWidth("30%");
    } else if (graphData.length > 3 && graphData.length < 10) {
      setColumnWidth("50%");
    } else if (graphData.length >= 10) {
      setColumnWidth("100%");
    }
  }, [graphData]);

  return (
    <>
      {props.isLoading ? (
        <>
          <Skeleton className="grapg-skeleton" />
          <Skeleton className="grapg-skeleton" />
          <Skeleton className="grapg-skeleton" />
          <Skeleton className="grapg-skeleton" />
          <Skeleton className="grapg-skeleton" />
          <Skeleton className="grapg-skeleton" />
        </>
      ) : (
        <Chart
          className="meeting-custom-graph"
          series={seriesArray}
          width={"100%"}
          height={screenHeight}
          options={{
            chart: {
              type: "line",
              offsetX: 0,
              offsetY: 0,
              height: "auto",
              stacked: false,
              toolbar: {
                autoSelected: "pan",
                show: true,
                offsetX: -10,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                },
              },
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [1, 1, 1, 2],
              curve: "smooth",
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.3,
                // gradientToColors: ['#FF9F43', '#FEB019'],
                inverseColors: true,
                opacityFrom: 0.9,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: columnWidth,
                barHeight: "80%",
                borderRadius: 0,
                borderRadiusApplication: "end",
                dataLabels: {
                  position: "top",
                },
              },
            },
            colors: colorArray,
            xaxis: {
              type: "category",
              categories: user,
              labels: {
                minHeight: 0,
                show: true,
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
                trim: true,
                style: {
                  colors: ["#5f5f5f"],
                  fontSize: "10px",
                  fontWeight: 500,
                },
              },
              // tickPlacement: "on",
            },
            yaxis: {
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                minWidth: 0,
                minHeight: 0,
                style: {
                  colors: ["#5f5f5f"],
                  fontSize: "10px",
                  fontWeight: 600,
                },
              },
            },

            legend: {
              show: true,
              fontSize: legendFont,
              position: "bottom",
              horizontalAlign: "left",
              offsetX: 0,
              offsetY: 0,
              markers: {
                width: legendMark.width,
                height: legendMark.height,
              },
              itemMargin: {
                horizontal: 8,
                vertical: 5,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    show: true,
                  },
                },
              },
            ],
          }}
        />
      )}
    </>
  );
};

export const ClaimsChart = () => {
  let themeColor = localStorage.getItem("theme");
  let colorArray = [];
  if (themeColor == "dark") {
    colorArray = ["#04a96d", "#24e5a3", "#fcb939", "#7ce5aa"];
  } else {
    colorArray = ["#5842bc", "#7e69db", "#ff847f", "#b2a5ee"];
  }
  return (
    <Chart
      series={[
        {
          name: "Approved",
          type: "column",
          data: [60000, 70000, 75000, 66000],
        },
        {
          name: "Pending",
          type: "column",
          data: [20000, 7000, 6000, 24000],
        },
        {
          name: "Rejected",
          type: "column",
          data: [10000, 3000, 4000, 2000],
        },
        {
          name: "Total Applied",
          type: "line",
          data: [90000, 80000, 85000, 92000],
        },
      ]}
      width={"100%"}
      height={330}
      options={{
        chart: {
          type: "line",
          offsetX: 0,
          offsetY: 0,
          height: "auto",
          stacked: false,
          toolbar: {
            autoSelected: "pan",
            show: false,
          },
        },
        grid: {
          show: true,
          strokeDashArray: 2,
          position: "back",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 3],
          curve: "smooth",
        },
        // plotOptions: {
        //   bar: {
        //     horizontal: false,
        //     columnWidth: "40%",
        //     barHeight: "80%",
        //     borderRadius: 0,
        //     borderRadiusApplication: "end",
        //     dataLabels: {
        //       position: "top",
        //     },
        //   },
        // },
        colors: colorArray,

        xaxis: {
          type: "category",
          categories: ["JAN-2023", "FEB-2023", "MAR-2023", "APR-2023"],
          // labels: {
          //   minHeight: 30,
          //   maxHeight: 0,
          //   style: {
          //     colors: ["#5f5f5f"],
          //     fontSize: "10px",
          //     fontWeight: 600,
          //   },
          // },
        },
        yaxis: {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            minWidth: 20,
            minHeight: 10,
            formatter: function (val) {
              return val / 1000 + "K";
            },
            style: {
              colors: ["#5f5f5f"],
              fontSize: "10px",
              fontWeight: 600,
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val / 1000 + "K";
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 15,
          // customLegendItems: [
          //   "Total Applied - 90K",
          //   "Approved - 60K",
          //   "Pending - 20K",
          //   "Rejected - 10K",
          // ],
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       legend: {
        //         show: false,
        //       },
        //     },
        //   },
        // ],
      }}

      // className="claim-chart-apex"
    />
  );
};

export const MeetingStatusChart = {
  options: {
    chart: {
      // width: 100,
      // height: 330,
      height: "auto",
      type: "radialBar",
    },

    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          // size: "25%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            fontSize: "14px",
          },
          value: {
            fontSize: "15px",
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    },
    colors: ["#5842bc", "#7e69db", "#ff847f"],
    labels: ["Direct Visit", "Scheduled Visit", "My Visit"],
    legend: {
      show: true,
      floating: true,
      fontSize: "13px",
      position: "left",
      offsetX: -10,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },

      itemMargin: {
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  },
};

export const MultiChart = (props) => {
  let themeColor = localStorage.getItem("theme");
  let colorArray = [];
  if (themeColor == "dark") {
    colorArray = ["#04a96d", "#24e5a3", "#fcb939", "#7ce5aa"];
  } else {
    colorArray = ["#5842bc", "#7e69db", "#ff847f", "#b2a5ee"];
  }

  let graphData = props.graphData;
  const [totalMeeting, setTotalMeeting] = useState([]);
  const [scheduleMeeting, setScheduleMeeting] = useState([]);
  const [directMeeting, setDirectMeeting] = useState([]);
  const [myMeeting, setMyMeeting] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (graphData) {
      let totalArray = [];
      let scheduledArray = [];
      let directArray = [];
      let myVisitArray = [];
      let userArray = [];
      graphData.map((item) => {
        totalArray.push(item.Total);
        scheduledArray.push(item.Scheduled);
        directArray.push(item.Direct);
        myVisitArray.push(item.MyVisit);
        userArray.push(item.Date);
      });
      setTotalMeeting(totalArray);
      setScheduleMeeting(scheduledArray);
      setDirectMeeting(directArray);
      setMyMeeting(myVisitArray);
      setUser(userArray);
    }
  }, [graphData]);
  return (
    <Chart
      series={[
        {
          name: "Approved",
          type: "column",
          data: scheduleMeeting,
        },
        {
          name: "Pending",
          type: "column",
          data: directMeeting,
        },
        {
          name: "Rejected",
          type: "column",
          data: myMeeting,
        },
        {
          name: "Total Applied",
          type: "line",
          data: totalMeeting,
        },
      ]}
      width={"100%"}
      height={380}
      options={{
        chart: {
          type: "line",
          offsetX: 0,
          offsetY: 0,
          height: "auto",
          stacked: false,
          toolbar: {
            autoSelected: "pan",
            show: false,
          },
        },
        grid: {
          show: true,
          strokeDashArray: 2,
          position: "back",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: "40%",
            // barHeight: "80%",
            borderRadius: 0,
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: colorArray,

        xaxis: {
          type: "category",
          categories: user,
          labels: {
            minHeight: 0,
            show: true,
            rotate: -25,
            rotateAlways: true,
            hideOverlappingLabels: false,
            trim: true,
            style: {
              colors: ["#5f5f5f"],
              fontSize: "10px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            minWidth: 0,
            minHeight: 0,
            // formatter: function (val) {
            //   return val / 1000 + "K";
            // },
            style: {
              colors: ["#5f5f5f"],
              fontSize: "10px",
              fontWeight: 600,
            },
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val / 1000 + "K";
        //     },
        //   },
        // },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "left",
          offsetX: 10,
          offsetY: 5,
          // customLegendItems: [
          //   "Total Applied - 90K",
          //   "Approved - 60K",
          //   "Pending - 20K",
          //   "Rejected - 10K",
          // ],
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      }}
    />
  );
};
