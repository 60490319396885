import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
import "../../styles/MeetingDetailsModel.scss";
import { Image } from "react-bootstrap";

function MeeterReadingModel(props) {
  const [meeterReadingDetails, setMeeterReadingDetails] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchMeeterReadingDetails();
  }, []);

  const fetchMeeterReadingDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeeterReading/Get?&UserId=${props.UserId}&FromDate=${props.ViewDate}&ToDate=${props.ViewDate}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeeterReadingDetails(res.result);
        } else {
          setMeeterReadingDetails("");
        }
      });
  };
  return (
    <div>
      <Modal
        show={props.meeterReadingModel}
        onHide={props.closeMeetrReadModel}
        className="meeter-reading-model"
      >
        <div className="add-user-heading">
          <h6 className="add-user-head">
            {props.userName} : MEETER READING DETAILS - {props.ViewDate}
          </h6>
          <i className="fa fa-times" onClick={props.closeMeetrReadModel} />
        </div>
        <div className="details-body">
          <div className="table-responsive meetings-scrollbar">
            <table className="table meetings-list-table">
              <thead className="thead-light meetings-list-thead">
                <tr>
                  <th>NAME</th>
                  <th>DATE</th>
                  <th>CHECK-IN READING</th>
                  <th>CHECK-OUT READING</th>
                  <th>DISTANCE</th>
                </tr>
              </thead>
              <tbody>
                {meeterReadingDetails !== "" ? (
                  meeterReadingDetails.map((data, i) => (
                    <tr key={i}>
                      <td>{data?.fullName}</td>
                      <td>{data?.date}</td>
                      <td>
                        <div className="checkin-reading-with-attach">
                          <div className="meeter-reading start">
                            {data?.checkInReading}
                          </div>
                          <Image
                            src={`${baseURL}/${data?.checkInAttachment}`}
                            className="checkinreading-attachment"
                            alt="Check-In Attachment"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="checkin-reading-with-attach">
                          <div className="meeter-reading end">
                            {data?.checkOutReading}
                          </div>
                          <Image
                            src={`${baseURL}/${data?.checkOutAttachment}`}
                            className="checkinreading-attachment"
                            alt="Check-In Attachment"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="meeter-reading distance">
                          {data?.distance}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      -----NO DATA FOUND----
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MeeterReadingModel;
