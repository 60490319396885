import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AccessDenied from "../../AccessDenied";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { useNavigate } from "react-router-dom";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import Footer from "../Footer";

function KilometerRefreshAttendance() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [manager, setManager] = useState("");
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  console.log(totalData, "total");

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [userID, setUserID] = useState("");
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const [companyList, setCompanyList] = useState([]);
  const [subType, setSubType] = useState(0);
  useEffect(() => {
    fetchDropdownData();
  }, [subType]);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindUsersDropDown?SubscriptionId=${subType}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const fetchDropdowns = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown?SubscriptionTypeID=0`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCompanyList(
            res.result.map((data) => {
              return {
                value: data.subscriptionID,
                label: data.companyName,
              };
            })
          );
        } else {
          setCompanyList([]);
        }
      });
  };

  useEffect(() => {
    getMeetings();
  }, [PageSize, currentPage, filterUserId, fromDate, toDate, subscriptionId]);

  // GET MEETINGS PAGE START
  const getMeetings = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/SupportTeam/GetAllCheckoutWith0Kms?SubscriptionID=${subscriptionId}&PageSize=${PageSize}&CurrentPage=${currentPage}&UserId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format("DD/MM/YYYY")}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("ATTENDANCE-REFRESH-PAGE::", res.result);
        if (res.success) {
          setAttendanceData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          res.result.forEach((element) => {
            element.inputState = {
              chkSelectRefresh: false,
              attendanceID: "String",
              startLocLatitude: 0,
              startLocLongitude: 0,
              startLocLocation: 0,
              endLocLocLatitude: 0,
              endLocLocLongitude: 0,
              endLocLocLocation: 0,
            };
            setTotalData(res.recordCount[0]);
            setIsLoading(false);
            setLoading(false);
          });
        } else {
          setAttendanceData("");
          setIsLoading(false);
          setLoading(false);
        }
        setSelectAllCheck(false);
      });
  };
  // GET MEETINGS PAGE END

  const setMeetingIdforRefresh = (state, attendanceID) => {
    if (attendanceID !== 0) {
      let refreshKm = attendanceData.filter(
        (x) => x.attendanceID == attendanceID
      );
      console.log("REFRESH-KM***", refreshKm);
      if (refreshKm.length > 0) {
        refreshKm[0].inputState.chkSelectRefresh = state;
        refreshKm[0].inputState.attendanceID = refreshKm[0].attendanceID;
        refreshKm[0].inputState.startLocLatitude =
          refreshKm[0].checkInLoc.latitude;
        refreshKm[0].inputState.startLocLongitude =
          refreshKm[0].checkInLoc.longitude;
        refreshKm[0].inputState.startLocLocation =
          refreshKm[0].checkInLoc.location;
        refreshKm[0].inputState.endLocLocLatitude =
          refreshKm[0].checkOutLoc.latitude;
        refreshKm[0].inputState.endLocLocLongitude =
          refreshKm[0].checkOutLoc.longitude;
        refreshKm[0].inputState.endLocLocLocation =
          refreshKm[0].checkOutLoc.location;
      }
      setSelectAllCheck(false);
    } else {
      attendanceData.forEach((data) => {
        data.inputState.chkSelectRefresh = state;
        data.inputState.attendanceID = data.attendanceID;
        data.inputState.startLocLatitude = data.checkInLoc.latitude;
        data.inputState.startLocLongitude = data.checkInLoc.longitude;
        data.inputState.startLocLocation = data.checkInLoc.location;
        data.inputState.endLocLocLatitude = data.checkOutLoc.latitude;
        data.inputState.endLocLocLongitude = data.checkOutLoc.longitude;
        data.inputState.endLocLocLocation = data.checkOutLoc.location;
      });
    }
    setAttendanceData([...attendanceData]);
  };

  const selectAllStatus = (event) => {
    if (
      attendanceData.map((data) => data.inputState.chkSelectRefresh == event)
    ) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  };

  const postRefreshKiloMeters = () => {
    let singleRefreshData = attendanceData.filter(
      (data) => data.inputState.chkSelectRefresh == true
    );
    console.log("SINGLE-REFRESH*****", singleRefreshData);
    if (singleRefreshData.length == 1) {
      let refreshData = [
        {
          attendanceID: singleRefreshData[0].inputState.attendanceID,
          meetingID: "",
          startLoc: {
            latitude: singleRefreshData[0].inputState.startLocLatitude,
            longitude: singleRefreshData[0].inputState.startLocLongitude,
            location: singleRefreshData[0].inputState.startLocLocation,
          },
          endLoc: {
            latitude: singleRefreshData[0].inputState.endLocLocLatitude,
            longitude: singleRefreshData[0].inputState.endLocLocLongitude,
            location: singleRefreshData[0].inputState.endLocLocLocation,
          },
        },
      ];

      // console.log("REFRESH-DATA==1:", refreshData);

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(refreshData),
      };
      fetch(
        `${baseURL}/api/SupportTeam/UpdateKmMeetingsAttandance`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("POST-REFRESH-KM::", res);
          if (res.success) {
            alert(res.message);
          } else {
            alert(res.errorMessage);
          }
        });
    } else {
      let refreshData = [];
      for (let i = 0; i < singleRefreshData.length; i++) {
        refreshData.push({
          attendanceID: singleRefreshData[i].inputState.attendanceID,
          meetingID: "",
          startLoc: {
            latitude: singleRefreshData[i].inputState.startLocLatitude,
            longitude: singleRefreshData[i].inputState.startLocLongitude,
            location: singleRefreshData[i].inputState.startLocLocation,
          },
          endLoc: {
            latitude: singleRefreshData[i].inputState.endLocLocLatitude,
            longitude: singleRefreshData[i].inputState.endLocLocLongitude,
            location: singleRefreshData[i].inputState.endLocLocLocation,
          },
        });
      }
      // console.log("REFRESH-DATA > 1:", refreshData);

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(refreshData),
      };
      fetch(
        `${baseURL}/api/SupportTeam/UpdateKmMeetingsAttandance`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("POST-REFRESH-KM::", res);
          if (res.success) {
            alert(res.message);
          } else {
            alert(res.errorMessage);
          }
        });
    }
  };

  const clearSerachBySelect = () => {
    setSubType(0);
    setFilterUserId("");
    setManager("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          KM REFRESH (ATTENDANCE)
                        </h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div className="clients-filter-input-boxs filter-box-div">
                            <button
                              type="button"
                              className="btn btn-search-track"
                              onClick={postRefreshKiloMeters}
                              style={{ borderRadius: "3px", padding: "6px" }}
                            >
                              <span className="btn-inline-item d-flex">
                                <i className="fa fa-refresh"> </i>
                                <h6>Update Km's</h6>
                              </span>
                            </button>
                          </div>
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          {/* <SbAddBtn onClickEffect={showAddmeetingmodel} /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={9}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={companyList}
                                  placeholder="Select Company"
                                  value={subType === 0 ? "" : companyList.value}
                                  onInputChange={companyList.label}
                                  onChange={(data) => setSubType(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userList}
                                  placeholder="Select User"
                                  // value={userList.value}
                                  value={
                                    filterUserId === "" ? "" : userList.value
                                  }
                                  onInputChange={userList.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                  // isClearable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date date-filter"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(date) =>
                                      setSelectSearchDrop(date)
                                    }
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={1}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            {/* <div className="clients-filter-input-boxs filter-box-div">
                              <button
                                type="button"
                                className="btn btn-search-track"
                                onClick={postRefreshKiloMeters}
                                style={{ borderRadius: "3px", padding: "6px" }}
                              >
                                <span className="btn-inline-item d-flex">
                                  <i className="fa fa-refresh"> </i>
                                  <h6>Refresh</h6>
                                </span>
                              </button>
                            </div> */}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div
                    className="table-responsive meetings-scrollbar"
                    onScroll={(e) =>
                      handleScroll(
                        e,
                        attendanceData,
                        totalData,
                        setLoading,
                        setPageSize,
                        PageSize
                      )
                    }
                  >
                    <table className="table meetings-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>
                            <span className="select-all-check-box-text">
                              <input
                                className="select-all-checkbox"
                                type="checkbox"
                                value=""
                                id="0"
                                onClick={(e) => {
                                  setMeetingIdforRefresh(e.target.checked, 0);
                                  selectAllStatus(e.target.checked);
                                }}
                                checked={selectAllCheck}
                              />
                              <span>Select All </span>
                              <span style={{ paddingLeft: "15px" }}>NAME </span>
                            </span>
                          </th>
                          <th>MOBILE</th>
                          <th>DATE</th>
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceData != "" ? (
                          attendanceData.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>
                                <span className="meeting-list-name d-flex">
                                  <input
                                    className="select-single-checkbox"
                                    type="checkbox"
                                    value=""
                                    id={data?.attendanceID}
                                    onClick={(e) => {
                                      setMeetingIdforRefresh(
                                        e.target.checked,
                                        data?.attendanceID
                                      );
                                    }}
                                    checked={data?.inputState?.chkSelectRefresh}
                                  />

                                  <h6>{data?.userFullName}</h6>
                                </span>
                              </td>
                              <td>{data?.userContact}</td>
                              <td>{data?.attendanceDate}</td>
                              <td style={{ maxWidth: "200px" }}>
                                {data?.checkInLoc?.location}
                                <span className="client-meet-location">
                                  <p className="client-meet-lat">
                                    <i className="bi bi-geo-alt" />
                                    {data?.checkInLoc?.latitude},
                                    {data?.checkInLoc?.longitude}
                                  </p>
                                </span>
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                {data?.checkOutLoc?.location}
                                <span className="client-meet-location">
                                  <p className="client-meet-lat">
                                    <i className="bi bi-geo-alt" />
                                    {data?.checkOutLoc?.latitude},
                                    {data?.checkOutLoc?.longitude}
                                  </p>
                                </span>
                              </td>
                              <td>{data?.totalKms}</td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="no-data-found">
                            ------ NO DATA FOUND ------
                          </h6>
                        )}
                        {loading && <OnScrollLoading />}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default KilometerRefreshAttendance;
