import { useEffect, useState } from "react";
import { baseURL } from "../../BaseUrl";
import * as swal from "../../Consturl/SwalAlert";
import BulkUploadComp from "../../ReUseComponents/BulkUploadComp";

export default function ReAssignCustomerBulk(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadExcelFile, setUploadExcelFile] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.show, props.handleClose]);

  const addBulkMachines = () => {
    if (uploadExcelFile !== null || uploadExcelFile !== "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(`${baseURL}/api/Import/ImportUpdateCustomerId `, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleClose();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      <BulkUploadComp
        pageFrom="Machine"
        show={props.show}
        closeModel={closeModel}
        heading="Re-Assign Customers"
        handleChange={handleChange}
        downloadTemplateUrl={"BulkUploadTemplate/Customer_ID_Template.xlsx"}
        uploadClick={addBulkMachines}
        bulkUploadResponse={bulkUploadResponse}
        isLoading={isLoading}
      />
    </div>
  );
}
