import "../../styles/AddUserDetails.scss";
import Select from "react-select";
import "../../styles/AddUserModel.scss";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import Multiselect from "multiselect-react-dropdown";
import profileimg from "../../images/profile-1.png";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { baseURL } from "../BaseUrl";
import { loadCityList, loadStateList } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as swal from "../Consturl/SwalAlert";
import "react-toastify/dist/ReactToastify.css";
import { FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import Geocode from "react-geocode";
import { React_Google_API_Key } from "../../GoogleApiKey";
import { optionsMapByListModel } from "../DropdownsData";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";
Geocode.setApiKey(React_Google_API_Key);

const UpdateUserData = (props) => {
  let dispatch = useDispatch();

  /* HEADER AUTH START */
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  const salesbeeImgAuth = { Travelize_Authentication: userDetails };
  /* HEADER AUTH END */
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const [fullname, setFullname] = useState("");
  const [usercode, setUserCode] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [mobilenum, setMobileNum] = useState(0);
  const [role, setRole] = useState("");
  const [stateForHoliday, setStateForHoliday] = useState({
    label: "",
    value: "",
  });
  const [location, setLocation] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [manager, setManager] = useState("");
  const [enableStatus, setEnableStatus] = useState();
  const [country, setCountry] = useState("");
  const [shift, setShift] = useState(0);
  const [oldShift, setOldShift] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [localImage, setLocalImage] = useState(null);
  const [zone, setZone] = useState([]);
  const [state, setState] = useState([]);
  // console.log(state, "state");
  const [city, setCity] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateListDropdown, setStateListDropdown] = useState([]);
  const [cityLists, setCityList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);

  const [managerList, setManagerList] = useState([]);
  const [zoneId, setZoneId] = useState([]);
  const [selectedOldCities, setSelectedOldCities] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [imgFileName, setImgFileName] = useState("");
  const [zoneIds, setZoneIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [address, setAddress] = useState("");
  const [mapById, setMapById] = useState(0);
  const [userRegionResult, setUserRegionResult] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);

  const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
  const [showMachineDetailsHeader, setShowMachineDetailsHeader] =
    useState(true);
  const [activeMenu, setActiveMenu] = useState(1);

  const toggleMachineDetails = () => {
    setShowMachineDetailsForm(true);
    setShowMachineDetailsHeader(true);
    setActiveMenu(1);
  };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
  };

  const addressFromGoogle = () => {
    openGoogleMaps();
  };

  const hiddenFileInput = useRef("");

  const handleChange = (event) => {
    setProfileImageUrl(true);
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    dispatch(loadStateList(0));
    fetchDropdownData();
  }, [zoneIds, stateIds]);

  const fetchDropdownData = () => {
    let stateResponse = userRegionResult?.allStates;
    setStateListDropdown(
      stateResponse
        ?.filter((z) => zoneIds.includes(z.zoneId))
        ?.map((item) => item) ?? []
    );
    let cityResponse = userRegionResult?.allCities;
    setCityList(
      cityResponse
        ?.filter((s) => stateIds.includes(s.stateId))
        ?.map((item) => item) ?? []
    );
  };

  useEffect(() => {
    fetchZoneStateCityselectedList();
  }, [props.userID, reportDesignation]);

  const fetchZoneStateCityselectedList = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    // SHIFT LIST API CALL
    fetch(`${baseURL}/api/Zone/BindShiftDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShiftList(
            res.result.map((data) => {
              return { value: data.shiftID, label: data.shiftName };
            })
          );
        } else {
          setShiftList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    // SHIFT LIST API CALL
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result
              .filter((desig) => desig.designationLevel !== 1)
              .map((data) => {
                return {
                  value: data.designationID,
                  label: data.designationName,
                };
              })
          );
        } else {
          setDesignationList([]);
        }
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });

    fetch(
      `${baseURL}/api/Zone/GetUserRegionsResult/?userId=${props.userID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        let regionResult = res.result;
        setCountryList(
          regionResult?.allCountry.map((data) => {
            return { value: data.countryID, label: data.countryName };
          })
        );
        setCountryCodeList(
          regionResult?.allCountryCode.map((data) => {
            return { value: data.code, label: data.code };
          })
        );
        // setShiftList(res.result.allShift);
        setZoneList(regionResult?.allZones);
        setUserRegionResult(regionResult);
        setCountryId(regionResult?.selectedCountryID[0]);
        setCountry(regionResult?.selectedCountry?.countryName);
        let zones = [
          ...new Set(regionResult.userRegions.map((z) => z.zoneName)),
        ];
        let states = [
          ...new Set(regionResult.userRegions.map((s) => s.stateName)),
        ];
        let cities = [
          ...new Set(regionResult.userRegions.map((c) => c.cityName)),
        ];
        setSelectedOldCities(cities);
        setZone(
          regionResult.allZones.filter((z) => zones.includes(z.zoneName))
        );
        console.log(regionResult, "regionResult");
        setState(
          regionResult.allStates.filter((s) => states.includes(s.stateName))
        );
        setCity(
          regionResult.allCities.filter((c) => cities.includes(c.cityName))
        );
        let zonesId = [...new Set(res.result.userRegions.map((z) => z.zoneId))];

        let statesId = [
          ...new Set(regionResult.userRegions.map((s) => s.stateId)),
        ];
        let citiesId = [
          ...new Set(regionResult.userRegions.map((c) => c.cityId)),
        ];
        setZoneIds(zonesId);
        setStateIds(statesId);
        setCityIds(citiesId);
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleRemoveZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleSelectState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleRemoveState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleSelectCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  const handleRemoveCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  // FETCH USERDETAILS FOR EDIT START
  useEffect(() => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/UserDetails/${props.userID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setFullname(res.result?.fullName);
        setMinMeetingRange(res.result?.allowMeetings);
        setUserCode(res.result?.code);
        setEmailAddress(res.result?.email);
        setMobileNum(res.result?.contact);
        setRole(res.result?.roleId);
        setLocation(res.result?.baseLoc?.location);
        setLatLan({
          lat: JSON.parse(res.result?.baseLoc?.latitude),
          lng: JSON.parse(res.result?.baseLoc?.longitude),
        });
        setCountryCode(res.result?.countryCode);
        setManager(res.result?.managerId);
        setEnableStatus(res.result?.enableStatus);
        setProfileImageUrl(res.result?.profilePic);
        setShift(res.result?.shiftID);
        setOldShift(res.result?.shiftID);
        setMapById(res.result?.mapType);
        setBranch(res.result?.branch);
        setStateForHoliday(res.result?.stateSpecificHoliday);
        setReportDesignation(res.result?.desingation?.designationID);
      })
      .catch((error) => {
        console.error("ERROR:---", error);
        setErrorMessage(error.message);
      });
  }, [props.userID]);
  // FETCH USERDETAILS FOR EDIT END

  //  ADDING ZONE STATE AND CITY BY USER START
  const addZoneStateCity = (resUserId) => {
    const zoneStateCity = city.map((item) => {
      return { ...item, userId: resUserId };
    });
    state.forEach((s_state) => {
      if (
        zoneStateCity.filter((zsc) => zsc.stateId == s_state.stateId).length ==
        0
      ) {
        zoneStateCity.push({ ...s_state, userId: resUserId });
      }
    });
    zone.forEach((z_Zone) => {
      if (
        zoneStateCity.filter((zsc) => zsc.zoneId == z_Zone.zoneId).length == 0
      ) {
        zoneStateCity.push({ ...z_Zone, userId: resUserId });
      }
    });

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(zoneStateCity),
    };

    fetch(`${baseURL}/api/Zone/AddUserRegions`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // swal.showAlert("Success", `${res.message}`, "success");
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };
  //  ADDING ZONE STATE AND CITY BY USER END

  //  ADDING PROFILE IMAGE BY USER START
  const editProfileImg = (e) => {
    e.preventDefault();
    if (profileImageUrl == true && imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            var imgUrl = res.result[0];
            editUser(imgUrl);
          } else {
            setProfileImageUrl(res.success);
            setImgFileName(`${res.errorMessage}`);
          }
        });
    } else {
      editUser();
    }
  };
  //  ADDING PROFILE IMAGE BY USER END

  const [countryVal, setCountryVal] = useState("");
  const [designationVal, setDesignationVal] = useState("");
  const [managerVal, setManagerVal] = useState("");
  const [countryName, setCountryName] = useState("");
  const [shiftVal, setShiftVal] = useState("");
  const [mapByVal, setMapByVal] = useState("");
  const [minMeetingRange, setMinMeetingRange] = useState("");
  const [branch, setBranch] = useState({ value: "", label: "" });

  // VALIDATIONS START
  const checkValidation = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNum(e.target.value);
    } else {
      setFieldReq(true);
      setAlertMessage({ alertType: "fails", message: "Accept only numbers" });
    }
  };
  // VALIDATIONS END

  // MAPPING BY USER START
  const postuserMapping = () => {
    if (mapById !== mapByVal.value) {
      let userMapData = {
        userID: props.userID,
        mapType: JSON.parse(mapByVal.value),
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(userMapData),
      };
      fetch(`${baseURL}/api/User/UpdateClientMaping`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.showEditModel();
            props.getUserDetails();
            // swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.showEditModel();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        });
    }
  };
  // MAPPING BY USER END

  // SET EXISTING VALUE TO DROPDOWN START

  useEffect(() => {
    if (countryCode !== null && countryCode !== "") {
      countryCodeList.map((contry) => {
        if (countryCode == contry.value) {
          setCountryVal(contry);
        }
      });
    }
    if (reportDesignation !== null && reportDesignation !== "") {
      designationList.map((designation) => {
        if (reportDesignation == designation.value) {
          setDesignationVal(designation);
        }
      });
    }
    if (manager !== null && manager !== "") {
      managerList.map((mngrlist) => {
        if (manager == mngrlist.value) {
          setManagerVal(mngrlist);
        }
      });
    }
    if (countryId !== null && countryId !== "") {
      countryList.map((cntrList) => {
        if (countryId == cntrList.value) {
          setCountryName(cntrList);
        }
      });
    }
    if (shift !== null && shift !== "") {
      shiftList.map((shifts) => {
        if (shift == shifts.value) {
          setShiftVal(shifts);
        }
      });
    }

    if (mapById !== null && mapById !== "") {
      optionsMapByListModel.map((maplist) => {
        if (mapById == maplist.value) {
          setMapByVal(maplist);
        }
      });
    }
    cityList.map((city) => {
      if (city.label == branch) {
        setBranch(city);
      }
    });
    stateList.map((state) => {
      if (state.label == stateForHoliday) {
        setStateForHoliday(state);
      }
    });
  }, [
    manager,
    countryCode,
    reportDesignation,
    countryId,
    shift,
    shiftList,
    mapById,
    managerList,
    cityList,
    stateList,
  ]);
  // SET EXISTING VALUE TO DROPDOWN END

  // EDIT USER START
  const editUser = (imgUrl) => {
    if (
      countryCode !== "" &&
      mobilenum.toString().length == 10 &&
      role !== "" &&
      location !== "" &&
      country !== "" &&
      zoneIds !== "" &&
      zoneIds.length > 0 &&
      stateIds !== "" &&
      stateIds.length > 0 &&
      cityIds !== "" &&
      cityIds.length > 0 &&
      shift !== null &&
      shift !== 0
    ) {
      const editUserDetails = {
        desingation: {
          designationID: reportDesignation,
        },

        userId: props.userID,
        fullName: fullname,
        email: emailaddress,
        profilePic: imgUrl,
        countryCode: countryCode,
        contact: JSON.parse(mobilenum),
        code: usercode,
        enableStatus: enableStatus,
        // allowMeetings: "YES",
        managerId: manager,
        shiftID: JSON.parse(shift),
        mapType: mapById,
        allowMeetings: minMeetingRange,
        baseLoc: {
          latitude: JSON.stringify(latLan.lat),
          longitude: JSON.stringify(latLan.lng),
          location: location,
        },
        stateSpecificHoliday: stateForHoliday.label,
        branch: branch.label,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(editUserDetails),
      };

      if (oldShift != shift) {
        let requestOptionShift = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify({
            userID: props.userID,
            shiftID: JSON.parse(shift),
          }),
        };
        fetch(`${baseURL}/api/User/UpdateShift`, requestOptionShift)
          .then((res) => res.json())
          .then((res) => {
            // console.log("SHIFT-CHANGE-RES::", res);
          });
      }

      fetch(`${baseURL}/api/User/EditUser`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            let resUserId = props.userID;
            // if (IsCityChange) {
            deleteOldRegion(resUserId);
            // }
            // addZoneStateCity(resUserId);
            props.showEditModel();
            props.getUserDetails();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.showEditModel();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
          postuserMapping();
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required",
      });
    }
  };
  // EDIT USER END

  // DELETE OLD REGION START
  const deleteOldRegion = (resUserId) => {
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Zone/DeleteUserRegions?userId=${resUserId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        addZoneStateCity(resUserId);
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // DELETE OLD REGION END

  // GOOGLE MAPS LOCATION START
  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          // console.log("ADDRESS-RESPONSE:::", response.results[0]);
          setLatLan({
            lat: +lat,
            lng: +lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: +e.latLng.lat(),
      lng: +e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan) {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(response.results[0].formatted_address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  // GOOGLE MAPS LOCATION END

  useEffect(() => {
    dispatch(loadCityList());
  }, []);

  const closeModel = () => {
    props.showEditModel();
  };

  return (
    <AddEditFormComponent
      handleClose={closeModel}
      formName={"Employee Details"}
      formSubTitle={
        " Please enter employee infomation and proceed to the next step."
      }
      sidebarItems={["Employee Details"]}
      childrens={
        <div style={{ position: " relative" }} className="amc-single-image">
          <form onSubmit={editProfileImg}>
            <div className="row">
              <div className="col-lg-3 col">
                <div className="add-user-main-logo-image">
                  {localImage ? (
                    <img src={localImage} alt="" className="add-user-images" />
                  ) : (
                    <img
                      className="add-user-images"
                      src={
                        profileImageUrl !== "" &&
                        profileImageUrl !== null &&
                        profileImageUrl !== "no_img.png"
                          ? `${baseURL}/${profileImageUrl}`
                          : profileimg
                      }
                      alt="Profile Images"
                    />
                  )}
                  <div>
                    <button
                      type="button"
                      className="btn upload-logo"
                      onClick={uploadClick}
                    >
                      <i className="bi bi-camera-fill" />
                    </button>
                    <input
                      id="getFile"
                      type="file"
                      name="filetobase64"
                      accept="image/*"
                      className="btn-primary upload"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                {profileImageUrl == false && imgFileName ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted text-center"
                  >
                    {`${imgFileName}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-9 col">
                <div>
                  <div
                    className="row add-user-image-container-div"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Full Name *"
                          className={`${
                            fullname
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="Full Name *"
                            value={fullname}
                            onChange={(e) => {
                              setFullname(e.target.value);
                            }}
                            // required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Employee Code *"
                          className={`${
                            usercode
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="Employee Code *"
                            value={usercode}
                            onChange={(e) => setUserCode(e.target.value)}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row add-user-image-container-div"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="E-Mail Address *"
                          className={`${
                            emailaddress
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="email"
                            autoComplete="off"
                            className="form-control add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="E-Mail Address *"
                            value={emailaddress}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <div className="input-mobile-field d-flex">
                          <div className="country-code">
                            <FloatingLabel
                              label="Code *"
                              className={`${
                                countryCode
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                defaultValue={countryCodeList[null]}
                                options={countryCodeList}
                                placeholder="Code *"
                                value={countryVal}
                                onInputChange={countryCodeList.label}
                                onChange={(data) => setCountryCode(data.value)}
                                isSearchable={true}
                                // required
                              />
                            </FloatingLabel>
                          </div>

                          <div className="mobile-num" style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Mobile *"
                              className={`${
                                mobilenum
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="tel"
                                autoComplete="off"
                                className="form-control add-user-input"
                                id="exampleFormControlInput1"
                                placeholder="Mobile *"
                                value={mobilenum}
                                maxLength={10}
                                onChange={(e) => checkValidation(e)}
                                required
                              />
                            </FloatingLabel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Designation *"
                          className={`${
                            reportDesignation
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={designationList[null]}
                            options={designationList}
                            placeholder="Designation *"
                            value={designationVal}
                            onInputChange={designationList.label}
                            onChange={(data) =>
                              setReportDesignation(data.value)
                            }
                            isSearchable={true}
                            // required
                          />
                        </FloatingLabel>
                        {(fieldReq && reportDesignation === "") ||
                        (fieldReq && reportDesignation === 0) ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Reporting *"
                          className={`${
                            manager
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={managerList[null]}
                            options={managerList}
                            placeholder="Select Reporting *"
                            value={managerVal}
                            onInputChange={managerList.label}
                            onChange={(data) => setManager(data.value)}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Country *"
                          className={`${
                            countryName
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={countryList[null]}
                            options={countryList}
                            placeholder="Select Country *"
                            value={
                              countryName ? countryName : countryList.value
                            }
                            onInputChange={countryList.label}
                            onChange={(data) => setCountry(data.value)}
                          />
                        </FloatingLabel>
                        {fieldReq && country === "" ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Zone *"
                          className={`${
                            zone.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Multiselect
                            className="select-add-user-model zone model-select"
                            options={zoneList} // Options to display in the dropdown
                            selectedValues={zone} // Preselected value to persist in dropdown
                            onSelect={handleSelectZone} // Function will trigger on select event
                            onRemove={handleRemoveZone} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="zoneName" // Property name to display in the dropdown options
                            placeholder={"Select Zone *"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            showArrow={true}
                            selectionLimit={3}
                          />
                        </FloatingLabel>
                        {fieldReq && zoneIds.length <= 0 ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select States *"
                          className={`${
                            state.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Multiselect
                            className="select-add-user-model"
                            options={stateListDropdown} // Options to display in the dropdown
                            selectedValues={state} // Preselected value to persist in dropdown
                            onSelect={handleSelectState} // Function will trigger on select event
                            onRemove={handleRemoveState} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="stateName" // Property name to display in the dropdown options
                            placeholder={"Select States *"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            groupBy="zoneName"
                            showArrow={true}
                          />
                        </FloatingLabel>
                        {fieldReq && stateIds.length <= 0 ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Cities *"
                          className={`${
                            city.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Multiselect
                            className="select-add-user-model"
                            options={cityLists} // Options to display in the dropdown
                            selectedValues={city} // Preselected value to persist in dropdown
                            onSelect={handleSelectCity} // Function will trigger on select event
                            onRemove={handleRemoveCity} // Function will trigger on remove event
                            showCheckbox={true}
                            displayValue="cityName" // Property name to display in the dropdown options
                            placeholder={"Select Cities *"}
                            emptyRecordMsg="No options available"
                            avoidHighlightFirstOption={true}
                            closeIcon="cancel"
                            groupBy="stateName"
                            showArrow={true}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Shift *"
                          className={`${
                            shift
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={shiftList[null]}
                            options={shiftList}
                            placeholder="Select Shift"
                            value={shiftVal}
                            onInputChange={shiftList.label}
                            onChange={(data) => setShift(data.value)}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                        {fieldReq && (shift == null || shift == "") ? (
                          <small id={`Error`} className="form-text text-muted ">
                            {alertMessage.message}&nbsp;
                            <i className="fa fa-exclamation-circle" />
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Map By Method *"
                          className={`${
                            mapByVal.value
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            defaultValue={optionsMapByListModel[null]}
                            options={optionsMapByListModel}
                            placeholder="Map User By :"
                            value={mapByVal.value && mapByVal}
                            onInputChange={optionsMapByListModel.label}
                            onChange={(data) => setMapByVal(data)}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="State-Specific Holiday *"
                          className={`${
                            stateForHoliday
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            options={stateList}
                            placeholder="Select State-Specific Holiday"
                            value={stateForHoliday.value && stateForHoliday}
                            onInputChange={stateList.label}
                            onChange={(data) => setStateForHoliday(data)}
                            isSearchable={true}
                            autoComplete={false}
                            // required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Minimum Range For Meetings(KMs)"
                          className={`${
                            minMeetingRange
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="Minimum Range For Meetings(KMs)"
                            value={minMeetingRange}
                            onChange={(e) => {
                              setMinMeetingRange(e.target.value);
                            }}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mb-3 input-address-location">
                        <FloatingLabel
                          label="Location *"
                          className={`${
                            location
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            name="locationtxt"
                            wrap="hard"
                            className="form-control form-textarea add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="Location (Please click the icon for location)"
                            value={location}
                            readOnly={true}
                            // onChange={(e) => setLocation(e)}
                          />
                        </FloatingLabel>
                        {fieldReq && location === "" ? (
                          <small
                            id={`Error${alertMessage.alertType}`}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}
                          </small>
                        ) : (
                          ""
                        )}
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-googlemap`}>
                              Google Maps
                            </Tooltip>
                          }
                        >
                          <div>
                            <i
                              className="bi bi-geo-alt"
                              onClick={openGoogleMaps}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="col-lg-6 col">
                      <div className="mb-3">
                        <FloatingLabel
                          label="Select Branch *"
                          className={`${
                            branch.value !== ""
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            options={cityList}
                            placeholder="Select Branch"
                            value={branch.value && branch}
                            onInputChange={cityList.label}
                            onChange={(data) => setBranch(data)}
                            isSearchable={true}
                            autoComplete={false}
                            // required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row add-user-image-container-div">
                    <div className="col-lg-6 col">
                      <div className="mt-3">
                        <span className="enable-status d-flex">
                          <h6>Enable</h6>
                          <span className="enable-radio d-flex">
                            <div className="form-check enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={enableStatus == 1 ? true : false}
                                onChange={() => {
                                  setEnableStatus(1);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={enableStatus == 0 ? true : false}
                                onChange={() => {
                                  setEnableStatus(0);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                              >
                                No
                              </label>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-page-save-cancel">
              {showAlert ? (
                <div className={`response-message ${alertMessage.alertType}`}>
                  <p>{alertMessage.message}</p>
                </div>
              ) : (
                ""
              )}
              <SBSaveUpdateBtn btnName="Update" />
              <SbCancelBtn
                btnName="Cancel"
                onClickEffect={props.showEditModel}
              />
            </div>
          </form>
          {googleMapsModel && (
            <GoogleMapAddressModel
              googleMapsModel={googleMapsModel}
              addressFromGoogle={addressFromGoogle}
              openGoogleMaps={openGoogleMaps}
              latLan={latLan}
              searchLocation={searchLocation}
              searchVal={searchVal}
              onMarkerDragEnd={onMarkerDragEnd}
              address={address}
            />
          )}
        </div>
      }
    />
  );
};

export default UpdateUserData;
