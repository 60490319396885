import "../../styles/OrderStatus.scss";
import "../../styles/CommonStyle.scss";
import {
  dateFormatIST,
  dateFormatOnlyDate,
  dateFormatOnlyTime,
} from "../FormattedAmount";

const OrderStatus = (props) => {
  // console.log(props.orderHistory, "props.orderHistory")
  return (
    <div className="order-status-div">
      <div className="order-status-div1">
        <div className="orderTime">
          <span>
            <b>{dateFormatIST(props.orderDate)}</b>
          </span>
        </div>
        <div className="card1">
          <div className="circle"></div>
        </div>
        <div className="progressLine">
          <div className="line"></div>
        </div>
        <div className="order-status-inner-div">
          <div className="order-confirm-status">
            <span>Order Confirmed</span>
          </div>
          <div className="order-confirm-status">
            <p>{props.itemName}.</p>
          </div>
        </div>
      </div>
      {props.orderHistory.map((history, i) => (
        <div className="order-status-div1" key={i}>
          <div className="orderTime">
            <div>
              <span className="row-head-Text">
                {dateFormatOnlyDate(history.statusDate)}
              </span>
            </div>
            <div>
              <span className="row-sub-text">
                {dateFormatOnlyTime(history.statusDate)}
              </span>
            </div>
          </div>
          <div className="card1">
            <div className="circle"></div>
          </div>
          {i != props.orderHistory.length - 1 && (
            <div className="progressLine">
              <div className="line"></div>
            </div>
          )}
          <div className="order-status-inner-div">
            <div className="order-confirm-status">
              <span>Dispatched QTY {history.qtY_Sent} </span>
            </div>
            <div className="order-confirm-status">
              <p>{history.statusDescription}.</p>
            </div>
          </div>
        </div>
      ))}
      {/* <div className='order-status-div1'>
                <div className='orderTime'>
                    <span><b>Fri, 4th Aug - 5:05am</b></span>
                </div>
                <div className="card1">
                    <div className="circle"></div>
                </div>
                <div className="progressLine">
                    <div className="line"></div>
                </div>
                <div className='order-status-inner-div'>
                    <div className="order-confirm-status">
                        <span>Shipped</span>

                    </div>
                    <div className="order-confirm-status">
                        <p>EKART Logistics - FMPP1793</p>
                        <p>Fri, 4th Aug'23 - 5:05am</p>
                    </div>
                </div>
            </div>
            <div className='order-status-div1'>
                <div className='orderTime'>
                    <span>Fri, 4th Aug - 3:52pm</span>
                </div>
                <div className="card1">
                    <div className="circle"></div>
                </div>
                <div className="progressLine">
                    <div className="line"></div>
                </div>
                <div className='order-status-inner-div'>
                    <div className="order-confirm-status">
                        <span>Out For Delivery</span>

                    </div>
                    <div className="order-confirm-status">
                        <p>Your item is out for delivery</p>
                        <p>Fri, 4th Aug - 3:52pm</p>
                    </div>       
                </div>
            </div>
            <div className='order-status-div1'>
                <div className='orderTime'>
                    <span>Fri, 4th Aug - 9:38pm</span>
                </div>
                <div className="card1">
                    <div className="circle"></div>
                </div>
                
                <div className='order-status-inner-div'>
                    <div className="order-confirm-status">
                        <span>Delivered </span>

                    </div>
                    <div className="order-confirm-status">
                        <p>Your item has been delivered</p>
                        <p>Fri, 4th Aug - 9:38pm</p>
                    </div>
                </div>
                
            </div> */}
    </div>
  );
};

export default OrderStatus;
