import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Image, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleAmcData } from "../../redux/action";
import { baseURL } from "../BaseUrl";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function AnnualContractAttachments() {
  let navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  const { amcId } = state;
  console.log(amcId, "amcId");
  const { getSingleAmcData } = useSelector((state) => state.data);
  console.log(getSingleAmcData, "getSingleAmcData");

  let dispatch = useDispatch();
  useEffect(() => {
    if (amcId) {
      dispatch(loadSingleAmcData(amcId));
    }
  }, [amcId]);

  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if (amcId && getSingleAmcData) {
      if (Object.keys(getSingleAmcData).length !== 0) {
        let galleryArray = [];
        if (getSingleAmcData[0]?.galaryImage) {
          getSingleAmcData[0]?.galaryImage.split(",").forEach((galimg) => {
            galleryArray.push(galimg);
          });
        }
        setGalleryImages(galleryArray);
      }
    }
  }, [amcId, getSingleAmcData]);

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">MACHINE ATTACHMENTS</h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="product-details-model">
              <div className="row">
                <div className="col-md-5 col-sm-12">
                  {galleryImages.length !== 0 ? (
                    <div className="product-detail-left-part">
                      <div className="product-big-img">
                        {isLoading ? (
                          <div style={{ padding: "20px 0px" }}>
                            <Skeleton />
                          </div>
                        ) : (
                          <Swiper
                            spaceBetween={10}
                            navigation={false}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                          >
                            {galleryImages.length != 0 &&
                              galleryImages.map((data, i) => (
                                <SwiperSlide>
                                  <Image
                                    src={`${baseURL}/${data}`}
                                    alt="attachment-photo"
                                    className="product-img"
                                  />
                                </SwiperSlide>
                              ))}
                          </Swiper>
                        )}
                      </div>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <div className="carousel-sliding-part">
                          <Swiper
                            style={{
                              "--swiper-navigation-color": "#000000",
                              "--swiper-pagination-color": "#000000",
                              "--swiper-navigation-size": "13px",
                              "--swiper-navigation-sides-offset": "0px",
                            }}
                            onSwiper={setThumbsSwiper}
                            spaceBetween={5}
                            slidesPerView={4}
                            // slidesPerGroup={1}
                            loadSingleProduct
                            navigation={true}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                          >
                            {galleryImages.length != 0 &&
                              galleryImages.map((imageUrl, i) => (
                                <SwiperSlide key={i}>
                                  <div className="product-small-images-row">
                                    <div className="product-small-image">
                                      <Image
                                        src={`${baseURL}/${imageUrl}`}
                                        alt="attachment-photo"
                                        className="product-img"
                                      />
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                          </Swiper>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div style={{ padding: "20px 20px" }}>
                      <img
                        src="../../../../images/DefaultImages/NodataFound.jpg"
                        alt=""
                        style={{ width: "10%" }}
                      />
                      <br />
                      <span>No Attachments Found...!</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
